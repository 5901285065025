import React, { useEffect } from 'react';
import AddGroupAccess from './component/AddGroupAccess';
import { Box, Button, Grid, Typography } from '@mui/material';
import PersonPage from './component/PersonPage';
import DeviceAndDoorPage from './component/DeviceAndDoorPage';
import { useTranslation } from 'react-i18next';
import AddTimeSlot from './component/AddTimeSlot';
import { useState } from 'react';
import { GetContext } from '../../AppStore';
import CommonUtil from '../../util/CommonUtils';
import moment from "moment-timezone";
import { useSnackbar } from 'notistack';
import { createQuickSetup } from '../../services/AccessGroupService';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import { REACT_URL_QUICK_SETUP } from '../../constants/ReactEndPoints';
import api from '../../configurations/http-common';
import { getPermissionsByRoleId } from '../../services/RoleService';
import RightViewLayout from '../../components/RighViewLayout';

const CommonPage = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const value = GetContext();
  const [loader, setLoader] = useState(false)
  const [roles, setRoles] = React.useState([]);

  const convertToISOInUTC = (date) => {
    if (date) {
      const offset = (0 >= 0 ? '+' : '-') + Math.abs(0).toString().padStart(2, '0') + ':00';
      return date.format('YYYY-MM-DDTHH:mm:ss') + offset;
    }
  };

  useEffect(() => {
    getPermissionsByRoleId(api.decoded()?.roleId).then((res) => {
      var l = [];
      res.data?.data?.rolePermissions.map((item, index) => {
        //setRoles(...roles,item.permissionCode);
        l.push(item.code);
      });
      setRoles(l);
    });
  }, []);

  const checkPermission = (code) => {
    if (api.decoded() != undefined &&
      (api.decoded()?.roleCode === 'OWNER' || api.decoded()?.roleCode === 'SUPERADMIN'))
      return true;
    return roles.includes(code);
  }

  const resetContext = () => {
    value.setQuickSetupPayload({
      accessGroup: {
        name: "",
        schedulerId: "",
        startTime: "",
        endTime: ""
      },
      personIds: [],
      doorIds: []
    });
    value.setErrorQuickSetupPayload({
      accessGroup: {
        name: "",
        schedulerId: "",
        startTime: "",
        endTime: ""
      },
      personIds: '',
      doorIds: ''
    });
  }

  const handleSubmit = () => {

    if (CommonUtil.isEmptyString(value.quickSetupPayload.accessGroup.name)) {
      enqueueSnackbar(t('AccGroupAccessTime049'), { variant: 'error' })
      return;
    }
    if (CommonUtil.isEmptyString(value.quickSetupPayload.accessGroup.schedulerId)) {
      enqueueSnackbar(t('AccGroupAccessTime018'), { variant: 'error' })
      return;
    }
    if (CommonUtil.isEmptyString(value.quickSetupPayload.accessGroup.startTime)) {
      enqueueSnackbar(t('AccGroupAccessTime019'), { variant: 'error' })
      return;
    }
    if (CommonUtil.isEmptyString(value.quickSetupPayload.accessGroup.endTime)) {
      enqueueSnackbar(t('AccGroupAccessTime020'), { variant: 'error' })
      return;
    }

    const startDateTime = moment(new Date(value.quickSetupPayload.accessGroup.startTime));
    const endDateTime = moment(new Date(value.quickSetupPayload.endTime));

    if (startDateTime.isAfter(endDateTime)) {
      enqueueSnackbar(t('AccGroupAccessTime038'), { variant: 'error' })
      return;
    }

    if ((value.quickSetupPayload.accessGroup.startTime).isSame(moment("Fri Jan 01 2038"), "day")) {
      enqueueSnackbar(t('AccGroupAccessTime039'), { variant: 'error' })
      return;
    }

    if ((value.quickSetupPayload.accessGroup.endTime).isSame(moment("Fri Jan 01 2038"), "day")) {

      enqueueSnackbar(t('AccGroupAccessTime039'), { variant: 'error' })
      return;
    }

   if ((value.quickSetupPayload.accessGroup.startTime).isAfter(moment("Fri Jan 01 2038"), "day")) {
      enqueueSnackbar(t('AccGroupAccessTime039'), { variant: 'error' })
      return;
    }

    if ((value.quickSetupPayload.accessGroup.endTime).isAfter(moment("Fri Jan 01 2038"), "day")) {
      enqueueSnackbar(t('AccGroupAccessTime039'), { variant: 'error' })
      return;
    }

    // if (CommonUtil.isEmpty(value.quickSetupPayload.doorIds)) {
    //   value.setErrorQuickSetupPayload({
    //     personIds: '',
    //     accessGroup: {
    //       name: "",
    //       schedulerId: "",
    //       startTime: "",
    //       endTime: ""
    //     },
    //     doorIds: t('AccGroupAccessTime046')
    //   });
    //   enqueueSnackbar(t('AccGroupAccessTime046'), { variant: 'error' })
    //   return;
    // }
    // if (CommonUtil.isEmpty(value.quickSetupPayload.personIds)) {
    //   value.setErrorQuickSetupPayload({
    //     doorIds: "",
    //     accessGroup: {
    //       name: "",
    //       schedulerId: "",
    //       startTime: "",
    //       endTime: ""
    //     },
    //     personIds: t('AccGroupAccessTime036')
    //   });
    //   enqueueSnackbar(t('AccGroupAccessTime036'), { variant: 'error' })
    //   return;
    // }

    const state = {
      accessGroup: {
        name: value.quickSetupPayload.accessGroup.name,
        schedulerId: value.quickSetupPayload.accessGroup.schedulerId,
        startTime: convertToISOInUTC(value.quickSetupPayload.accessGroup.startTime),
        endTime: convertToISOInUTC(value.quickSetupPayload.accessGroup.endTime)
      },
      personIds: value.quickSetupPayload.personIds,
      doorIds: value.quickSetupPayload.doorIds
    };
    setLoader(true)
    createQuickSetup(state).then((res) => {
      if (res?.data?.code === 'CAQSI0001') {
        resetContext();
        enqueueSnackbar(res?.data?.msg, { variant: 'success' })
        navigate('/group-access-time');
      } else {
        enqueueSnackbar((res.data.msg), { variant: 'error' })
      }
      setLoader(false)
    });

  };
  return (
    <>
      {loader ? <Loader open={loader} /> : <></>}
      {checkPermission("ADD_EDIT_ACCESS_GROUP") ?
        <>
          <RightViewLayout
            navigateBack={false}
            title={t('SIDEBAR022')}
            path={true}
            onClick={() => navigate(REACT_URL_QUICK_SETUP)}
          />
          <Box sx={{ marginTop: { xl: "35px", xs: "30px" }, width: "100%" }}>
            <Grid container>
              <Grid item xs={12}>
                <AddGroupAccess
                  addTimeSlot={() => { setReload(false); setOpen(true) }}
                  reloadTimeSlot={reload}
                />
              </Grid>

              <Grid item md={6} xs={12} sx={{ mt: { xl: "60px", xs: "50px" }, pr: { md: "20px", xs: "0px" } }}>
                <DeviceAndDoorPage />
              </Grid>

              <Grid item md={6} xs={12} sx={{ mt: { xl: "60px", xs: "50px" }, pl: { md: "20px", xs: "0px" } }}>
                <PersonPage />
              </Grid>
              <Grid item xs={12}>
                <Box style={{ display: 'flex', justifyContent: 'flex-end', margin: '30px 0px 0px 0px' }}>
                  <Button
                    id={"button-time-zone-cancel"}
                    onClick={() => {
                      resetContext();
                      navigate(REACT_URL_QUICK_SETUP);
                    }}
                    variant='outlined'
                    sx={{
                      width: { xl: '233px', xs: "180px" },
                      height: { xl: '64px', xs: "50px" },
                      marginRight: '24px',
                    }}
                  >
                    {t('COMMON009')}
                  </Button>
                  <Button
                    id={"button-time-zone-submit"}
                    variant='contained'
                    onClick={() => handleSubmit()}
                    sx={{
                      width: { xl: '233px', xs: "180px" },
                      height: { xl: '64px', xs: "50px" },
                    }}
                  >
                    {t('COMMON042')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box> </> : <Box marginTop={'150px'} width={'100%'}>
          <Typography textAlign={'center'}>
            {t("AccGroupAccessTime050")}
          </Typography></Box>}
      <AddTimeSlot open={open} close={() => { setReload(true); setOpen(false) }} />
    </>
  )
}

export default CommonPage
